
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/helpers/Debounce';
import BarItem from '@/shared/components/BarItem.vue';
import CounterButton from '@/shared/components/CounterButton.vue';
import { Zalto } from '@/shared/models/Zalto';

export default {
    props: {
        item: {
            type: Zalto,
            required: true,
        },
        zalto: {
            type: Zalto,
            required: true,
        },
        design: {
            type: String,
            required: false,
        },
        divider: {
            type: String,
            required: false,
        },
    },
    components: {
        BarItem,
        CounterButton,
    },
    setup(props: any) {
        const store = useStore();
        const itemRef = ref(props.item);
        const quantity = computed(() => Math.floor(itemRef.value.quantity / itemRef.value.units_per_parcel));

        function updateCart() {
            store.dispatch('CART_UPDATE', {
                item_no: String(itemRef.value.id),
                quantity: itemRef.value.quantity,
            });
        }

        const debouncer = debounce(() => {
            updateCart();
        }, 1000);

        function addToCart(amount: any) {
            const dataLayer = window.dataLayer || [];
            let eventType = 'add_to_cart';
            if (amount < 0) {
                eventType = 'remove_from_cart';
            }

            dataLayer.push({
                ecommerce: null,
            });

            dataLayer.push({
                event: eventType,
                ecommerce: {
                    currencyCode: 'EUR',
                    value: itemRef.value.price,
                    items: [
                        {
                            item_id: itemRef.value.no,
                            item_name: itemRef.value.name,
                            index: 0,
                            item_brand: itemRef.value.color,
                            item_category: itemRef.value.country.name,
                            item_category2: itemRef.value.region.name,
                            item_category3: itemRef.value.estate.name,
                            item_category4: `${itemRef.value.alcohol_percentage}%`,
                            item_category5: itemRef.value.grapes.map((grape) => grape.name).join(', '),
                            price: itemRef.value.price,
                            quantity: amount * itemRef.value.units_per_parcel,
                        },
                    ],
                },
            });

            itemRef.value.quantity += amount * itemRef.value.units_per_parcel;
            debouncer();
        }

        return {
            addToCart,
            quantity,
        };
    },
};
