
import ZaltoDetailItem from '@/components/ZaltoDetailItem.vue';
import BarItem from '@/shared/components/BarItem.vue';
import { Zalto } from '@/shared/models/Zalto';

export default {
    props: {
        items: {
            type: Array<Zalto>(),
            required: true,
        },
        zalto: {
            type: Zalto,
            required: true,
        },
    },
    components: {
        ZaltoDetailItem,
        BarItem,
    },
    setup() {
        return {};
    },
};
