import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item-col" }
const _hoisted_2 = { class: "item-col" }
const _hoisted_3 = { class: "item-col" }
const _hoisted_4 = { class: "item-col" }
const _hoisted_5 = { class: "item-col" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CounterButton = _resolveComponent("CounterButton")!
  const _component_BarItem = _resolveComponent("BarItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BarItem, {
      class: "items d-none d-lg-block",
      design: $props.design,
      divider: $props.divider
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString($props.item.id), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString($props.item.units_per_parcel === 1 ? $props.item.units_per_parcel + ' glas' : $props.item.units_per_parcel + ' glazen'), 1),
        _createElementVNode("div", _hoisted_3, " €" + _toDisplayString(String($props.item.price?.toFixed(2)).replace('.', ',')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["status", $props.item.inventory_color])
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CounterButton, {
            modelValue: $setup.quantity,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.quantity) = $event)),
            class: "text-center",
            onMinus: _cache[1] || (_cache[1] = ($event: any) => ($setup.addToCart($event))),
            onPlus: _cache[2] || (_cache[2] = ($event: any) => ($setup.addToCart($event)))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["design", "divider"]),
    _createVNode(_component_BarItem, { class: "item d-block d-lg-none" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "option-title" }, " Artikelnummer ", -1)),
          _createElementVNode("div", null, _toDisplayString($props.item.id), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "option-title" }, " Doos Inhoud ", -1)),
          _createElementVNode("div", null, _toDisplayString($props.item.units_per_parcel === 1 ? $props.item.units_per_parcel + ' glas' : $props.item.units_per_parcel + ' glazen'), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "option-title" }, " Prijs ", -1)),
          _createElementVNode("div", null, " €" + _toDisplayString(String($props.item.price?.toFixed(2)).replace('.', ',')), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "option-title" }, " Voorraad ", -1)),
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: _normalizeClass(["status my-2", $props.item.inventory_color])
            }, null, 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "option-title" }, " Aantal dozen ", -1)),
          _createVNode(_component_CounterButton, {
            modelValue: $setup.quantity,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.quantity) = $event)),
            class: "text-center",
            onMinus: _cache[4] || (_cache[4] = ($event: any) => ($setup.addToCart($event))),
            onPlus: _cache[5] || (_cache[5] = ($event: any) => ($setup.addToCart($event)))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    })
  ]))
}